import { FormControl, InputLabel, Select,MenuItem, IconButton, Button, Grid, Avatar } from "@mui/material";
import { Container } from "@mui/system";
import { arSD, DataGrid } from "@mui/x-data-grid";
import React, { Component, useEffect, useState } from "react";
import { CgArrowsExchangeAlt } from "react-icons/cg";
 

import grouppingService from "../services/groupping.services";
import PelgrimsService from "../services/Pelgrims.services";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { MdAddCircle, MdAddCircleOutline, MdDelete     } from "react-icons/md";
import SeleniumServices from "../services/seleniumServices";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";
import loadingicon from "./loading.gif"
import AgencyService from "../services/Agency.services";
import moment from "moment/moment";
import { Status } from "./status";
  
  export const   WakilSendSelector =({ ...props }) => {
    const [filter, setfilter] = useState ();
    const [groupidList1, setgroupidList1] = useState ();
    const [groupidList2, setgroupidList2] = useState ();
    const [groupsList, setgroupsList] = useState ([]);
    const [loading, setloading] = useState (false);
    const [loadingdata, setloadingdata] = useState (false);
    
    const [mutamarsList1, setmutamarsList1] = useState ([]);
    const [mutamarsList2, setmutamarsList2] = useState ([]);
    const [passportNumber, setpassportNumber] = useState ("");
    const[Agency,setAgency]=useState([]);
    const[selectedagency,setselectedagency]=useState(JSON.parse(localStorage.getItem("Agency")).id);
    const _grouppingService = new grouppingService();
    const _pelgrimsService = new PelgrimsService();
    const _seleniumServices = new SeleniumServices();
    const _agencyService = new AgencyService();
    function sendone(x){
   
   debugger
   if(x<=mutamarsList2.length-1){
 
      _seleniumServices.Sendtowakil( mutamarsList2[x], "",  mutamarsList2[x].groupename=groupsList.find(x=>x.id==groupidList1).name).then(res=>{
       
 
        //setmutamarsList2({...mutamarsList2[x],notes:res.Erreur })
        if(res.data.Erreur=="Success"){
          NotificationManager.success('تم الارسال '+mutamarsList2[x].arabicName+' بنجاح', '');
        
          mutamarsList2[x].notes=res.data.Erreur ;
          setmutamarsList2(mutamarsList2)
          _pelgrimsService.addMuatamr(mutamarsList2[x]);
        }else{
          NotificationManager.error(res.data.Erreur , '');
       
          mutamarsList2[x].notes=res.data.Erreur ;
          setmutamarsList2(mutamarsList2)
          _pelgrimsService.addMuatamr(mutamarsList2[x]);
        }
        
        sendone(x+1);
        try{
          setpassportNumber(mutamarsList2[x+1].passportNumber);
        }catch{}
      
      }
      ) }else{setloading(false)
      }
  
    }
    function send(){
     
      if(mutamarsList2.length>0 ){

        _seleniumServices. Startbrowser().then(res=>{
          setloading(true);
          NotificationManager.success('تم  فتح المتصفح بنجاح', '');
          setpassportNumber(mutamarsList2[0].passportNumber);
sendone(0);
       
        
        }).catch(error=>{
          NotificationManager.error('الرجاء تشغل برنامج الارسال او الاتصال بدعم الفني', '');
setloading(false);
        })
      
       
     
    }
    }
    function getgroups(x){
  
        _grouppingService.getgroups(x).then(res=>{
        
          setgroupsList(res.data.records);
      
        }).catch(res=>{
          NotificationManager.error('يرجى التحقق من البينات', '');
        }

        )
    }
    function getpelgrimsList1(x){
      
      setmutamarsList1([]);
 
      let counter =1;
      let counter2 =1;
  
      if( groupsList[x].fields.Mutamrs!=null)
      {
        groupsList[x].fields.Mutamrs.forEach(async element => {
        counter++;
        
        setTimeout(()=>{
          //your code
          _pelgrimsService.GetByGroupNamepic(element).then(res=>{
            setmutamarsList1((prevArray) => [...prevArray,  res.data]);
          
           counter2++;
          });


        },counter*200);
        
    
         
       
      });}
      
       
    }
  
 
  function exchangeall(x){
    mutamarsList1.map(x=>{
      setmutamarsList2(mutamarsList2=>[...mutamarsList2,x]);
    })
    setmutamarsList1([]);
    }
    function exchange(x){
  debugger
      setmutamarsList2(mutamarsList2=>[...mutamarsList2,x]);
      
      
      
      setmutamarsList1(mutamarsList1.filter(el=>el!=x));
    
        
    }
    function Delete(x){
      debugger
      if(groupidList1==x.groupingId){
        setmutamarsList1(mutamarsList1=>[...mutamarsList1,x]);
      }

      setmutamarsList2(mutamarsList2.filter(el=>el!=x));
  
    }
    const handleChange3 = (event) => {
        
      setselectedagency(event.target.value);
      getgroups(event.target.value);
      

      
  };
    const handleChange2 = (event) => {
        
        setgroupidList1(event.target.value);
        getpelgrimsList1(event.target.value)

        
    };
   
    
    const handleChange = (event) => {
        
        setfilter(event.target.value);
    };

    const columns = [
     
      { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
        return (
          <div style={{display:"flex"}}>
          
          <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
      
       
          <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
            <span className="namestyle">
              {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
              </span>
<span className="namestyle">
{parm.row.fields.firstName+" "+ parm.row.fields.lastName}
</span>
          </div>
          </div>
        )} },
      
 
      
      { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
  
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 60,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IconButton className="addicon" aria-label="addtolist" size="large" onClick={e=>exchange(parm.row)}>
        <MdAddCircleOutline   />
      </IconButton>
      
  
              </div>
            );
          }
        }
      ];
      const columns2 = [
     
        { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
          return (
            <div style={{display:"flex"}}>
            
            <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
        
         
            <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
              <span className="namestyle">
                {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
                </span>
  <span className="namestyle">
  {parm.row.fields.firstName+" "+ parm.row.fields.lastName}
  </span>
            </div>
            </div>
          )} },
        
   
        
        { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
    
        { field: 'notes', headerName: 'نتيجة الارسال', width: 250 },
  
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 80,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              !loading?
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IconButton   className="deleteicon"  aria-label="addtolist" size="large" onClick={e=>Delete(parm.row)}>
        <MdDelete />
      </IconButton>
      
  
              </div>:(passportNumber==parm.row.fields.passportNumber?<img src={loadingicon} style={{height:"35px"}}/>:"")
            );
          }
        }
      ];
  useEffect(() => {
     getgroups();
 
  },[])
  return(
    <div>
    <Grid   container spacing="10" >
      <Grid item   xs={3} sm={4} md={5}>
    <div>
     
 {/* <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label">بحث حسب  </InputLabel>
        <Select
        disabled={loading}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={filter}
      
          onChange={handleChange}
          
          label="بحث حسب"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          <MenuItem value={1}>بحث حسب المجموعة</MenuItem>
          <MenuItem value={2}>بحث حسب رقم الجواز</MenuItem>
          <MenuItem value={3}>بحث حسب اسم المعتمر</MenuItem>
        </Select>
      </FormControl> */}
   
        <div style={{display:"flex"}}>
       
        <FormControl sx={{ m: 1  }}>
        <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={groupidList1}
          disabled={loading}
          onChange={handleChange2}
          
          label=" اسم المجموعة"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          {
            groupsList.map( (x,index) =>{
              return <MenuItem value={index}>
                <div dir="RTL"> 
<table style={{fontSize:"14px"}}>
  <tr>
  <td style={{fontSize:"20px"}}>  {x.fields.name+" "}   </td>

  <td><Status  data={{"total":x.fields.totalmutamr,"visa":x.fields.totalvisa,"mouwaf9a":x.fields.totalmouwaf9a}}/> </td>
 
  
  </tr>
</table>


</div>
                
              
                
                </MenuItem>


          })
          }
 
        </Select>
      
      </FormControl>
      </div>
     
    </div>
     
    
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"65vh" }}
        rows={mutamarsList1}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        disabled={loading}
        loading={loadingdata}
      />
     
      
      </Grid>
      <Grid item>
      <div style={{height:"50vh"}}></div>
      <IconButton  className="exchangeall"  disabled={loading} aria-label="addtolist" size="large" onClick={e=>exchangeall()}>
        <FaArrowAltCircleLeft   />
      </IconButton>
      
      </Grid>
      <Grid item xs={3} sm={4} md={6}>

     
  
         <div style={{height:"70px"}}></div>
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"65vh" }}
            rows={mutamarsList2}
            columns={columns2}
            rowsPerPageOptions={[5,10,100,1000]}
            disabled={loading}
          />
         <Button  disabled={mutamarsList2.length==0||loading}  style={{ height:"50px",marginTop:"10px"}} size="large" variant="contained"   onClick={e=>send()}> ارسال الى الوكيل    </Button>
 
       </Grid>
      </Grid>
  

    </div>

     
    
  )
  };
