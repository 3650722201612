import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import Layout from "./layouts";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import ReactGA from 'react-ga';
function App() {
  return <Layout />;
}
 
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    console.log("oki");
     this.history.push("/");
     localStorage.clear();
  }});
export default App;
