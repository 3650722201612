import React, { useEffect, useState } from "react";
  
 import AgencyService from "../services/Agency.services";
 
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import countrylist from "../data/country.json"
import embasylist from "../data/embasy.json"
import scanner from "../data/scanner.json"
import wakil from "../data/walik.json"
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  MdDelete   , Forever,
  MdEdit,
  MdPhotoCamera,
  MdExpandMore
} from "react-icons/md";
import moment from "moment";
 
 
import { Modal } from "react-bootstrap";
 
 
 
export const    AgencySetting =(visible,{setvisible2}) => {
 
 
 
const[agency,setagency]=useState({
  
  adresse:"",
  agancyNameAr:"",
  agancyNameFrr:"",
  city:"",
  country:null,
  createdBy:"",
  logo:"",
  phone:"",
  scanner:1,
  updatedBy:"",
  userId: "",
  wakil:1,
  wakilUser:"",
  wakilpassword:"",

  updatedDate: null
});
 
const _agencyService = new AgencyService();

function addagency(){
  localStorage.setItem("Agency", JSON.stringify(agency));
  _agencyService.addagency(agency).then(res=>{
   
    
  
   NotificationManager.success('تم الحفظ بنجاح', '');

  
 
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )
}
 
 
function getphotoBase64(file) {
  let document = "";
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
     
    setagency({...agency,logo:reader.result})
     
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };

 
}



 
 

 
useEffect(()=>{
   
  console.log(JSON.parse(localStorage.getItem("Agency")));
  setagency(  JSON.parse(localStorage.getItem("Agency")));
 
 
},[ visible])
return(
  

 
 
  <div >
 
 
 
<Modal

 
 show={ visible.visible}
 onHide={ e=>{visible.setvisible2() }}
 size="lg"
 aria-labelledby="contained-modal-title-vcenter"
 centered
 dir="RTL"
>
<Grid   container  spacing="10"   >
 <Grid item  xs={6} sm={6} md={6}>

 <span className="tpg"> معلومات الوكالة</span>
<div className="userdiv"> 
   <TextField
 
 required
 label="اسم الوكالة العربية"
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,agancyNameAr:e.target.value})}}
 value={agency.agancyNameAr}
 />
  <TextField
  
 required
 label=" اسم الوكالة الاجنبي  "
 id="filled-basic"
 variant="outlined"
 value={agency.agancyNameFrr}
 onChange={e=>{setagency({...agency,agancyNameFrr:e.target.value})}}
  
 />

</div>
<div className="userdiv"> 

<TextField
 
 required
 label=" الهاتف  "
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,phone:e.target.value})}}
 value={agency.phone}
 />
 <TextField
  
 required
 label="العنوان"
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,adresse:e.target.value})}}
 value={agency.adresse}
 />
</div>
<div className="userdiv">  
<FormControl  >
       <InputLabel id="demo-simple-select-autowidth-label">الدولة  </InputLabel>
       <Select
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.country}
     
         onChange={e=>{setagency({...agency,country:e.target.value})}}
         
         label=" الدولة "
       >
        {
           countrylist.countries.country.map(x=>{
             return (<MenuItem value={x.name_country_ar} >{x.name_country_ar}</MenuItem>)
           })

         }
     
       </Select>
     </FormControl>
  
<FormControl >
       <InputLabel id="demo-simple-select-autowidth-label">القنصلية  </InputLabel>
       <Select
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.city}
     
         onChange={e=>{setagency({...agency,city:e.target.value.toString()})}}
         
         label=" القنصلية  "
       >
          {
        
      
        agency.country!=null?["",embasylist.OFFLINE_EMBASSY.EMBASSY.find(x=>x.phone==countrylist.countries.country.find(x=>x.name_country_ar==agency.country).phone)].map(x=>{
             return (<MenuItem value={x.EMB_NAME_AR}>{x.EMB_NAME_AR}</MenuItem>)
           }):""

         }
       </Select>
     </FormControl>
     </div>
 

     <span className="tpg">معلومات الوكيل</span>
<div className="userdiv"> 

 
     <FormControl >
       <InputLabel id="demo-simple-select-autowidth-label">الوكيل  </InputLabel>
       <Select
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.wakil}
     
         onChange={e=>{setagency({...agency,wakil:e.target.value})}}
         
         label=" الوكيل  "
       >
     {
           wakil.wakil.map(x=>{
             return (<MenuItem value={x.id} >{x.name_ar}</MenuItem>)
           })

         }
       </Select>
     </FormControl>
</div>
<div className="userdiv"> 
 <TextField
  
 required
 label=" اسم المستخدم"
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,wakilUser:e.target.value})}}
 value={agency.wakilUser}
 />
 <TextField
  
  required
  label="  كلمة المرور  "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setagency({...agency,wakilpassword:e.target.value})}}
  value={agency.wakilpassword}
  />


  
</div>
     
</Grid>
<div class="vl"></div>
<Grid item xs={5.9} sm={5.9} md={5.9}>
 <div style={{height:"25vh" ,display:"flex",justifyContent:"center"}}>
       <IconButton color="primary" aria-label="upload picture" component="label"  >
 <div style={{display:"felx"}}>
<Avatar alt="Remy Sharp" src={agency.logo }style={{width:"100px",height:"100px"}}/>

  <input hidden accept="image/*" type="file"    onChange={e=>{ 
    
    getphotoBase64(e.target.files[0])
 
     }}/>

  </div>
</IconButton> 
</div>
<hr></hr>
<div className="userdiv">
<FormControl >
       <InputLabel id="demo-simple-select-autowidth-label">الماسح الضوئي  </InputLabel>
       <Select
       rows={14}
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.scanner}
     
         onChange={e=>{setagency({...agency,scanner:e.target.value})}}
         
         label=" الماسح الضوئي "
       >
             {
           scanner.scannerlist.map(x=>{
             return (<MenuItem value={x.id} >{x.name_ar}</MenuItem>)
           })

         }
       </Select>
     </FormControl>
</div>



</Grid>

</Grid>
<hr></hr>
<div className="footermodal">
<Button className="save" size="large" variant="contained"  onClick={e=>addagency()} > حفظ</Button>
    
     <Button  className="cancel" size="large" variant="contained"   onClick={e=>{visible.setvisible2() }  } > الغاء</Button>

</div>
</Modal>
 
    </div>
 
 
)
}
 