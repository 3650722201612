import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
  

ReactDOM.render(

  <React.StrictMode>
 
    <App />
 
  </React.StrictMode>,
  document.getElementById("root")
);

 
