import request from "../configs";

// import { authHeader } from "./auth-header";
export default class AgencyService {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
    
      getagency(filter) {
      let requestOptions =  {
        headers: {
     
          Authorization:
          "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
           
        }
      };
       

 
      return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+`/Settings`,requestOptions);
    }
    getagencybyid(id) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.get(`/api/Agency`+id,requestOptions);
    }
    deleteagency(id) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.delete(`/api/Agency/`+id,requestOptions);
    }


          
    addagency(body){
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
      
      debugger
      if(typeof  body.id=="undefined"){
     
        return this.request.post("/api/Agency",body,requestOptions);
      }else{
        delete body.createdDate
        
        delete body.updatedDate
        return this.request.put("/api/Agency/"+body.id,body,requestOptions);
      }

    } 
    addagencywithuser(agency,user){
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
      
  
      if(typeof  agency.id=="undefined"){
      var body2={
        "agency":agency,
        "user":user
      }
        return this.request.post("/api/Agency/CreateAgncywithuser",body2,requestOptions);
      }else{
        delete agency.createdDate
        
        delete agency.updatedDate
        return this.request.put("/api/Agency/"+agency.id,agency.agency,requestOptions);
      }
   
    }
   
    

   
   
  }