import Agency from "../../screens/Agency/Agency";
import Login from "../../screens/Auth/Login";
 
 
import Dashboard from "../../screens/dashboard";
 
import Groups from "../../screens/Groups/Groups";
 
import AddPilegrim from "../../screens/Pelgrims/Addpelgrim";
import ExchangePelgrim from "../../screens/Pelgrims/exchangePelgrim";
import Importwakil from "../../screens/Pelgrims/Importwakil";
import PaymentGRID from "../../screens/Pelgrims/PaymentGRID";
import Reports from "../../screens/Repport/reports";
 
 
 
 
import Pelgrims from "../../screens/Pelgrims/Pelgrims";
import VisaSend from "../../screens/Pelgrims/VisaSend";
import WakilSend from "../../screens/Pelgrims/WakilSend";
 
import Users from "../../screens/Users/Users";
import NOSOKSend from "../../screens/Pelgrims/NOSOKSend";
 
 
export const routes = [
  //{
  //   title_ar: "",

  //   title: "",
  //   path: "/*",
  //   component: Dashboard,
  //   isPublic: false,
  //   exact: true,
  // },
  {
    title_ar: "",
    title: "ادارة المجموعات",
    path: "/",
    component: Groups,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",
    title: " الوكالة  ",
    path: "/Agency",
    component: Agency,
    isPublic: false,
    exact: true,
  },
  
   
   
  {
    title_ar: "",

    title: "ادارة المستخدم",
    path: "/Users",
    component: Users,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "ادارة المعتمرين",
    path: "/Pelgrims",
    component: Pelgrims,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "اضافة معتمر ",
    path: "/Pelgrims/Addpelgrim/:Groupid/:id",
    component: AddPilegrim,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "تحويل معتمر ",
    path: "/Pelgrims/exchangePelgrim",
    component: ExchangePelgrim,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "   ارسال الي الوكيل ",
    path: "/Pelgrims/WakilSend",
    component: WakilSend,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "   ارسال الي نسك ",
    path: "/Pelgrims/NOSOKSend",
    component: NOSOKSend,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "  طباعة التأشيرة   ",
    path: "/Pelgrims/VisaSend",
    component: VisaSend,
    isPublic: false,
    exact: true,
  },
 
  
  
  {
    title_ar: "",

    title: " تحميل البيانات  ",
    path: "/Import",
    component: Importwakil,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",

    title: "التقارير",
    path: "/Reports",
    component: Reports,
    isPublic: false,
    exact: true,
  },
 
 
  {
    title_ar: "",

    title: "ادارة الحسابات",
    path: "/payment",
    component: PaymentGRID,
    isPublic: false,
    exact: true,
  },
 
   
  
 
  {
    title_ar: "",

    title: "ادارة المجموعات",
    path: "/Groups",
    component: Groups,
    isPublic: false,
    exact: true,
  },
 
  {
    title_ar: "",

    title: "Login",
    path: "/login",
    component: Login,
    isPublic: true,
    exact: true,
  },
   
 
];
