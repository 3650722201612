import request from "../configs";

// import { authHeader } from "./auth-header";
export default class PelgrimsService {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
      
      GetByGroupNamepic(mutamrid) {
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
         
      
   
        return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+ '/Mutamrs/'+mutamrid,requestOptions);

       
      }
      GetByGroupName(groupeid) {
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
         
  
   
        return this.request.get(`/api/Muatamr/GetByGroupName/`+groupeid,requestOptions);

       
      }
      getPelgrims(id) {
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
         
  
   
        return this.request.get(`/api/Muatamr/`+id,requestOptions);

       
      }
      getbytext(x) {
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
         
  
   
        return this.request.get(`/api/Muatamr/Getbytext/`+x,requestOptions);

       
      }
      Updatewakilcode(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
        
      let list={"ListPilegrim":body};
 
          return this.request.post("/api/Muatamr/Updatewakilcode",list,requestOptions);
        
    
      }
      archive(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
        
    
 
          return this.request.post("/api/Muatamr/archive",body,requestOptions);
        
    
      }
      Updatetraveldata(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
        
    
 
          return this.request.post("/api/Muatamr/Updatetraveldata",body,requestOptions);
        
    
      }
      Updatevisa(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
        var body2={"passportNumber":body.passportNumber,"VisaPicture":body.VisapicPath,"visaNumber":body.visaNumber};
    
     
          return this.request.put("/api/Muatamr/Updatevisa/",body2,requestOptions);
       
    
      }
      addMuatamr(body){
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
        
       
          var body2= {"records": [
            {
               
              "fields": {
                "groupingId": [
                  body.groupingId
                ],
                "gender":  body.gender,
                "firstName": body.firstName ,
                "lastName":  body.lastName,
                "arabicName":  body.arabicName,
                "arabicLastName":  body.arabicLastName,
                "mrz": body.mrz,
                "passportNumber": body.passportNumber,
                "passportDelieveryDate": body.passportDelieveryDate,
                "passportExpiryDate":  body.passportExpiryDate,
                "passportDelieveryCity":  body.passportDelieveryCity,
                "passportDeliveryCountry":  body.passportDeliveryCountry,
                "nationalityCountry":  body.nationalityCountry,
                "birthDate":  body.birthDate,
                "birthCountry": body.birthCountry,
                "birthCity":  body.birthCity,
                "job":  body.job,
                "civilState":  body.civilState,
                "educationLevel":  body.educationLevel,
                "healthStatus":  body.healthStatus,
                "hasMuhram": body.hasMuhram,
                "addressCountryId": body.addressCountryId,
                "transport":  body.transport,
                "wayPort":  body.wayPort,
                "dateArrival":  body.dateArrival,
                "stayPeriod":  body.stayPeriod,
                "flightNumber":  body.flightNumber,
                "notes": body.notes,
                "pilgrimCode":  body.pilgrimCode,
                "Title1":  body.Title1,
                "createdDate":  body.createdDate,
                "updatedDate":  body.updatedDate,
                "pilgrimPicturePath":[{
                 
                  "url": body.pilgrimPicturePath,
                }],
                "passportPicturePath":[{
                 
                  "url": body.passportPicturePath,
                }]
                 
              }
            }
          ]
        }
     
          return this.request.post(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
       
    
      }
      exchangeMuatamr(body,id,id2){
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
     
          var body2= {"records": [
            {
               "id":id2,
              "fields": {
                "groupingId": [
                 id
                ],
                "gender":  body.gender,
                "firstName": body.firstName ,
                "lastName":  body.lastName,
                "arabicName":  body.arabicName,
                "arabicLastName":  body.arabicLastName,
                "mrz": body.mrz,
                "passportNumber": body.passportNumber,
                "passportDelieveryDate": body.passportDelieveryDate,
                "passportExpiryDate":  body.passportExpiryDate,
                "passportDelieveryCity":  body.passportDelieveryCity,
                "passportDeliveryCountry":  body.passportDeliveryCountry,
                "nationalityCountry":  body.nationalityCountry,
                "birthDate":  body.birthDate,
                "birthCountry": body.birthCountry,
                "birthCity":  body.birthCity,
                "job":  body.job,
                "civilState":  body.civilState,
                "educationLevel":  body.educationLevel,
                "healthStatus":  body.healthStatus,
                "hasMuhram": body.hasMuhram,
                "addressCountryId": body.addressCountryId,
                "transport":  body.transport,
                "wayPort":  body.wayPort,
                "dateArrival":  body.dateArrival,
                "stayPeriod":  body.stayPeriod,
                "flightNumber":  body.flightNumber,
                "notes": body.notes,
                "pilgrimCode":  body.pilgrimCode,
                "Title1":  body.Title1,
                "createdDate":  body.createdDate,
                "updatedDate":  body.updatedDate,
                "pilgrimPicturePath":body.pilgrimPicturePath,
                "passportPicturePath":  body.passportPicturePath
                 
              }
            }
          ]
        }
     debugger
          return this.request.put(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
       
    
      }

      Delete(body){
        let requestOptions =  {
          headers: {
            Authorization:
        
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
   
          return this.request.delete(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs/"+body.id,requestOptions)
       
    
      }


        uploadFileToServeur =  (file) => {
        const url = 'http://upload.awslabs.tn/Upload.php';
       
        const headers = {
        
          'Content-Type': 'multipart/form-data',
           
        };
      
        const imageType = file.split(";")[0].split(":")[1];

        // Extract the base64-encoded image data from the data URL
        const base64Image = file.split(",")[1];
        const formData = new FormData();
        formData.append('image', base64Image);
        formData.append("image_type", "jpg");
        
          return this.request.post(url, formData, { headers });
         
      };

  }