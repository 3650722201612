import React from "react";
 
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  BsFillPersonFill
  
} from "react-icons/bs";
import {TiGroup } from "react-icons/ti";
import { AiFillSetting, AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { FaCcVisa, FaExchangeAlt } from "react-icons/fa";
const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const history = useHistory();

  return (
    <ProSidebar
      //   image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="logo-container">
          {!collapsed && (
            <span
              className="logo"
              style={{
                color: "#fff",
                fontSize: "30px",
                fontWeight: "bolder",
                letterSpacing: "2px",
              }}
            >
               {" "}
            </span>
          )}
          <span
            className="btn-collapse "
            onClick={() => handleCollapsedChange(!collapsed)}
          >
            {collapsed ? (
              <AiOutlineDoubleRight></AiOutlineDoubleRight>
            ) : (
              <AiOutlineDoubleLeft></AiOutlineDoubleLeft>
            )}
          </span>
        </div>
      </SidebarHeader>

      <SidebarContent>

      <Menu iconShape="circle">
          <SubMenu className="primaryitem" title="المسافرون" icon={<TiGroup />}>
          <MenuItem onClick={() => history.push("/Groups")}>
          ادارة المجموعات{" "}
            </MenuItem>
            <MenuItem onClick={() => history.push("/Pelgrims")}>
            ادارة المعتمرين {" "}
            </MenuItem>
          
          <MenuItem onClick={() => history.push("/Pelgrims/exchangePelgrim")}>
           تحوبل المعتمرين{" "}
            </MenuItem>
            <MenuItem onClick={() => history.push("/Reports")}>
             التقارير  {" "}
            </MenuItem>
         {    /*
         <MenuItem onClick={() => history.push("/payment")}>
            ادارة الحسابات  {" "}
            </MenuItem> */}
          </SubMenu>
        </Menu>
        <Menu iconShape="circle">
          <SubMenu title="تبادل البيانات" icon={<FaExchangeAlt />}>
          <MenuItem onClick={() => history.push("/Pelgrims/WakilSend")}>
          ارسال البيانات{" "}
            </MenuItem>
            <MenuItem onClick={() => history.push("/Pelgrims/NOSOKSend")}>
          ارسال الي نسك{" "}
            </MenuItem>
            <MenuItem onClick={() => history.push("/Import")}>
             تحميل البيانات{" "}
            </MenuItem>
          </SubMenu>
        </Menu>
        <Menu iconShape="circle">
          <SubMenu title="التأشير الالي"icon={<FaCcVisa />}>
          <MenuItem onClick={() => history.push("/Pelgrims/VisaSend")}>
          طباعة التأشيرة{" "}
            </MenuItem>
           
          </SubMenu>
        </Menu>
        { JSON.parse(localStorage.getItem("user")).role=="Admin"?<Menu iconShape="circle">
          <SubMenu title="الاعدادات "icon={<AiFillSetting />}>
          <MenuItem onClick={() => history.push("/Users")}>
          المستخدم{" "}
            </MenuItem>
            <MenuItem onClick={() => history.push("/Agency")}>
             الوكالة{" "}
            </MenuItem>
          </SubMenu>
        </Menu>:""}
        

         
      
        
         
         
         

        
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        {!collapsed && (
          <div className="sidebar-footer">
            created by <span>Tarek Baccar</span>
          </div>
        )}
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
