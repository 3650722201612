import request from "../configs";

// import { authHeader } from "./auth-header";
export default class ReportingServices {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
      archive(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          },
          responseType: 'blob'
        };
      
          return this.request.post("/api/Raporting/getRapport",body,requestOptions);
      
        
    
      }
 
      getrapport(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          },
          responseType: 'blob'
        };
      
          return this.request.post("/api/Raporting/getRapport",body,requestOptions);
      
        
    
      }


     
  }